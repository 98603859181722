import axios from 'axios'
import {
  ChannelListReponse,
  ChannelSimpleResponse,
  ClearMessagesReponse,
  ConversationMessagesResponse,
  ConversationUserDetailsResponse,
  CreateGroupConversationReponse,
  DefaultChannelReponse,
  EditGroupConversationReponse,
  GroupConversationDetailsResponse,
  GroupConversationMessagesResponse,
  GroupListResponse,
  GroupParticipantUserId,
  MessageAttachmentResponse,
  SendConversationMessageReponse,
  UnreadMessagesCountReponse,
  UserChannelListResponse,
  UserListReponse,
  UserListStateReponse,
} from '../store/Messenger/types'
import authHeader from './authHeader'

const API_URL = process.env.REACT_APP_API_URL

const getUnreadMessagesCount = (): Promise<UnreadMessagesCountReponse> => {
  return axios.get(API_URL + '/api/admin/messenger/unread-messages-count', {
    headers: authHeader(),
  })
}

const getChannelList = (): Promise<ChannelListReponse> => {
  return axios.get(API_URL + '/api/admin/messenger/channel-list', {
    headers: authHeader(),
  })
}

const getChannelListSimple = (): Promise<ChannelSimpleResponse> => {
  return axios.get(API_URL + '/api/admin/messenger/channel-list/simple', {
    headers: authHeader(),
  })
}

const getUserList = (): Promise<UserListReponse> => {
  return axios.get(API_URL + '/api/admin/messenger/conversation/user-list', {
    headers: authHeader(),
  })
}

const getUserListState = (channelId: number): Promise<UserListStateReponse> => {
  return axios.get(
    API_URL + `/api/admin/messenger/conversation/user-list-state/${channelId}`,
    {
      headers: authHeader(),
    },
  )
}

const setDefaultChannel = (
  channelId: number,
): Promise<DefaultChannelReponse> => {
  return axios.post(
    API_URL + '/api/admin/messenger/default-channel',
    {
      channelId,
    },
    {
      headers: authHeader(),
    },
  )
}

const getConversationMessages = (
  channelId: number,
  peerId: number,
): Promise<ConversationMessagesResponse> => {
  return axios.get(
    API_URL +
      `/api/admin/messenger/conversation/messages/${channelId}/${peerId}`,
    {
      headers: authHeader(),
    },
  )
}

const sendConversationMessage = (
  channelId: number,
  peerId: number,
  messageContent: string | null,
  attachmentFilename: string | null,
  attachmentContent: string | null,
  isNotification: boolean,
): Promise<SendConversationMessageReponse> => {
  return axios.post(
    API_URL + '/api/admin/messenger/conversation/message',
    {
      channelId,
      peerId,
      messageContent,
      attachmentFilename,
      attachmentContent,
      isNotification,
    },
    {
      headers: authHeader(),
    },
  )
}

const getMessageAttachment = (
  attachmentUuid: string,
): Promise<MessageAttachmentResponse> => {
  return axios.get(
    API_URL + `/api/admin/messenger/message-attachment/${attachmentUuid}`,
    {
      headers: authHeader(),
    },
  )
}

const getConversationUserDetails = (
  userId: number,
): Promise<ConversationUserDetailsResponse> => {
  return axios.get(
    API_URL + `/api/admin/messenger/conversation/user-details/${userId}`,
    {
      headers: authHeader(),
    },
  )
}

const getGroupList = (channelId: number): Promise<GroupListResponse> => {
  return axios.get(
    API_URL + `/api/admin/messenger/group-conversation/list/${channelId}`,
    {
      headers: authHeader(),
    },
  )
}

const getGroupConversationMessages = (
  groupConversationId: number,
): Promise<GroupConversationMessagesResponse> => {
  return axios.get(
    API_URL +
      `/api/admin/messenger/group-conversation/messages/${groupConversationId}`,
    {
      headers: authHeader(),
    },
  )
}

const sendGroupConversationMessage = (
  groupConversationId: number,
  messageContent: string | null,
  attachmentFilename: string | null,
  attachmentContent: string | null,
  isNotification: boolean,
): Promise<SendConversationMessageReponse> => {
  return axios.post(
    API_URL + '/api/admin/messenger/group-conversation/message',
    {
      groupConversationId,
      messageContent,
      attachmentFilename,
      attachmentContent,
      isNotification,
    },
    {
      headers: authHeader(),
    },
  )
}

const getGroupConversationDetails = (
  groupConversationId: number,
): Promise<GroupConversationDetailsResponse> => {
  return axios.get(
    API_URL +
      `/api/admin/messenger/group-conversation/details/${groupConversationId}`,
    {
      headers: authHeader(),
    },
  )
}

const createGroupConversation = (
  channelId: number,
  name: string,
  iconFilename: string | null,
  iconContent: string | null,
  groupParticipants: GroupParticipantUserId[],
): Promise<CreateGroupConversationReponse> => {
  return axios.post(
    API_URL + '/api/admin/messenger/group-conversation/create',
    {
      channelId,
      name,
      iconFilename,
      iconContent,
      groupParticipants,
    },
    {
      headers: authHeader(),
    },
  )
}

const editGroupConversation = (
  groupConversationId: number,
  isActive: boolean,
  name: string,
  iconFilename: string | null,
  iconContent: string | null,
  groupParticipants: GroupParticipantUserId[],
): Promise<EditGroupConversationReponse> => {
  return axios.post(
    API_URL + '/api/admin/messenger/group-conversation/edit',
    {
      groupConversationId,
      isActive,
      name,
      iconFilename,
      iconContent,
      groupParticipants,
    },
    {
      headers: authHeader(),
    },
  )
}

const clearMessages = (clearToDate: string): Promise<ClearMessagesReponse> => {
  return axios.post(
    API_URL + '/api/admin/messenger/message/clear',
    {
      clearToDate,
    },
    {
      headers: authHeader(),
    },
  )
}

const getUserChannelList = (
  userId: number,
): Promise<UserChannelListResponse> => {
  return axios.get(
    API_URL + `/api/admin/messenger/channel-list/user/${userId}`,
    {
      headers: authHeader(),
    },
  )
}

const exportedObject = {
  getUnreadMessagesCount,
  getChannelList,
  getChannelListSimple,
  getUserList,
  getUserListState,
  setDefaultChannel,
  getConversationMessages,
  sendConversationMessage,
  getMessageAttachment,
  getConversationUserDetails,
  getGroupList,
  getGroupConversationMessages,
  sendGroupConversationMessage,
  getGroupConversationDetails,
  createGroupConversation,
  editGroupConversation,
  clearMessages,
  getUserChannelList,
}

export default exportedObject
